import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["element"];
    target = null;
    spinnerTimeout = null;
    disabledTimeout = null;
    retryAttempts = 0;
    retryTimeout = null;
    retryStartTime = null;
    maxRetryDuration = 20; // Maximum retry duration (20 seconds)
    retryDelay = 3; // Retry after X seconds

    connect() {
        document.addEventListener("ajax:error", this.handleAjaxError.bind(this));
        super.connect();
    }

    addLoader(event) {
        this.target = event.target;

        // Check if the auto-retry behavior is enabled
        if (!this.target.dataset.autoRetry || this.target.dataset.autoRetry === "false") {
            return; // Do not add loader or initiate auto-retry if disabled
        }

        event.target.classList.add("position-relative", "disabled");
        if (event.target.innerText) {
            event.target.dataset.originalText = event.target.innerText;
        }

        this.spinnerTimeout = setTimeout(() => {
            event.target.innerText = "";
            event.target.classList.add("spinner");
        }, 1000);

        this.disabledTimeout = setTimeout(() => {
            event.target.disabled = true;
        }, 100);
    }

    handleAjaxError(event) {
        if (this.target && this.target.dataset.autoRetry === "false") {
            this.resetState();
            return;
        }

        if (!this.retryStartTime) {
            this.retryStartTime = Date.now(); // Set the start time for retrying
        }

        const elapsedTime = Date.now() - this.retryStartTime;
        if (elapsedTime >= this.maxRetryDuration * 1000) {
            this.resetState();
            return;
        }

        this.retryAttempts++;
        console.log(`Retry attempt ${this.retryAttempts}`);

        // Resubmit the AJAX request after a short delay (e.g., 1 second)
        this.retryTimeout = setTimeout(() => {
            this.retryRequest();
        }, this.retryDelay * 1000);
    }

    retryRequest() {
        if (this.target) {
            // Temporarily enable the button to allow the click event
            this.target.disabled = false;

            // Trigger a click programmatically
            this.target.click();

            // Disable the button again
            this.target.disabled = true;
        }
    }

    resetState() {
        if (this.target) {
            this.target.classList.remove("disabled", "spinner");
            this.target.disabled = false; // Re-enable the button
            if (this.target.dataset.originalText) {
                this.target.innerText = this.target.dataset.originalText;
            }
        }
        this.clearTimeouts();
    }

    disconnect() {
        this.clearTimeouts();
        super.disconnect();
    }

    clearTimeouts() {
        if (this.spinnerTimeout) {
            clearTimeout(this.spinnerTimeout);
        }
        if (this.disabledTimeout) {
            clearTimeout(this.disabledTimeout);
        }
        if (this.retryTimeout) {
            clearTimeout(this.retryTimeout);
        }
        this.retryAttempts = 0;
        this.retryTimeout = null;
        this.retryStartTime = null;
    }
}
