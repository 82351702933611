import {Controller} from "stimulus"
import Rails from "@rails/ujs";
import * as RecordRTC from 'recordrtc';
import * as Hark from 'hark';

export default class extends Controller {

    static targets = ['answerContainer', 'answerForm', 'answerConstructionInput',
        'recordBtn', 'recordState', 'answerTranscriptInput',
        'placeholderAnswer', 'formSubmitBtn', 'instruction']
    static values = {cardType: String, solveUrl: String, transcriptUrl: String}
    constructedAnswer = [];
    pairCards = {}
    loaderTimeout = null;

    submitAnswer(e) {
        Rails.fire(this.answerFormTarget, 'submit');
    }


    answerFormTargetConnected() {
        this.inputElements = [
            ...this.answerFormTarget.querySelectorAll('input'),
            ...this.answerFormTarget.querySelectorAll('textarea')
        ]
        if (this.hasFormSubmitBtnTarget) {
            this.disableSubmitBtn();
            this.inputElements.forEach((input) => {
                input.addEventListener('input', (e) => {
                    const anyChecked = this.inputElements.some((inputElem) => inputElem.type == 'checkbox' && inputElem.checked)
                    const anyHasValue = this.inputElements.some((inputElem) => inputElem.type == 'textarea' && inputElem.value)
                    if (anyChecked || anyHasValue) {
                        this.enableSubmitBtn();
                    } else {
                        this.disableSubmitBtn();
                    }
                })
            })
        }
    }

    disableSubmitBtn() {
        this.formSubmitBtnTarget.disabled = true;
        this.formSubmitBtnTarget.classList.add('disabled')
    }

    enableSubmitBtn() {
        this.formSubmitBtnTarget.disabled = false;
        this.formSubmitBtnTarget.classList.remove('disabled')
    }

    constructAnswer(e) {
        let removal = false;

        if (e.target.checked) {
            this.constructedAnswer.push(e.target.value)
        } else {
            removal = true;
            const lastOccurrenceIdx = this.constructedAnswer.findLastIndex((x) => x == e.target.value)
            this.constructedAnswer.splice(lastOccurrenceIdx, 1);
        }
        const constructedAnswerArray = this.constructedAnswer;
        const constructedAnswer = this.constructedAnswer.join(' ');
        this.answerConstructionInputTarget.value = constructedAnswer;
        if (constructedAnswer.length > 0) {
            this.placeholderAnswerTarget.classList.add('text-underline')
            let result = constructedAnswerArray.map((ca, i, arr) => {
                let cssClass = (arr.length - 1 === i) && !removal ? "answer-preview-token pulse" : 'answer-preview-token';
                return `<span class='${cssClass}'>${ca}</span>`
            })

            this.placeholderAnswerTarget.innerHTML = result.join(' ');
        } else {
            this.placeholderAnswerTarget.classList.remove('text-underline')
            this.placeholderAnswerTarget.textContent = this.originalAnswerPlaceholder;
        }
    }

    recordAudio(e) {
        e.preventDefault()
        if (localStorage.getItem('audioPermissionGranted') === 'true') {
            this.setInstructionMessage('wait')
        }
        const maxTouchDurationBeforeTrigger = 150;
        this.longPressTimer = setTimeout(() => {
            this.startRecorder();
        }, maxTouchDurationBeforeTrigger)
    }

    startRecorder() {
        this.recorder = null;
        this.stopRecording = false;
        const maxRecordingTime = 10000 // 10 seconds
        this.clearStreamAndRecorder();
        this.captureAudio((audio) => {
            this.recorder = RecordRTC(audio, {
                    type: 'audio',
                    recorderType: RecordRTC.MediaStreamRecorder,
                    mimeType: 'audio/webm;codecs=opus'
                }
            );
            this.recorder.setRecordingDuration(maxRecordingTime).onRecordingStopped(this.stopRecordingCallback.bind(this));
            // If stop recording is set to true then immediately reset recording
            // This logic prevents the recorder to get stuck on the "recording" state
            // @see this.stopRecordingAudio()
            if (this.stopRecording) {
                this.setRecordingBtnState('record')
                this.clearStreamAndRecorder();
                return;
            }
            if(localStorage.getItem('audioPermissionGranted') === 'true'){
                this.recorder.startRecording();
                this.setInstructionMessage('speak')
                this.setRecordingBtnState('recording')
                this.recorder.audio = audio;
            }else{
                this.clearStreamAndRecorder();
            }

            localStorage.setItem('audioPermissionGranted', 'true');
        });
    }

    stopRecordingAudio(e) {
        e.preventDefault();
        this.stopRecording = true;
        this.setInstructionMessage('hide')
        this.instructionTarget.classList.add('d-none')

        if (this.longPressTimer) {
            clearTimeout(this.longPressTimer);
            this.longPressTimer = undefined;
        }
        if (this.recorder) {
            setTimeout(() => {
                this.recorder.stopRecording(this.stopRecordingCallback.bind(this))
            }, 250)
        }
    }

    captureAudio(callback) {
        this.setInstructionMessage('wait')
        window.navigator.mediaDevices.getUserMedia({audio: true, echoCancellation: true})
            .then((stream) => {
                this.setInstructionMessage('hide')
                this.stream = stream;
                callback(stream);
            }).catch((error) => {
            localStorage.setItem('audioPermissionGranted', 'false');
            this.setInstructionMessage('hide')
            this.dispatch("lilata:mobile-app-messenger-event", {
                prefix: false, detail: {
                    data: {
                        type: 'toast_message',
                        offset: 10,
                        message_type: 'permission_missing',
                        text_1: 'Recording permission missing',
                        text_2: 'Please grant the required permissions'
                    }
                }
            })
            console.error(error);
        })
    }

    setInstructionMessage(state){
        switch (state){
            case 'speak':
                this.instructionTarget.classList.remove('wait', 'd-none')
                this.instructionTarget.classList.add('speak')
                this.instructionTarget.textContent =  this.instructionTarget.dataset.contentSpeak;
                break;
            case 'wait':
                this.instructionTarget.classList.remove('speak', 'd-none')
                this.instructionTarget.classList.add('wait')
                this.instructionTarget.textContent =  this.instructionTarget.dataset.contentWait;
                break;
            case 'hide':
                this.instructionTarget.classList.add('d-none')
                this.instructionTarget.classList.remove('speak', 'wait')
                break;
        }

    }


    stopRecordingCallback() {
        this.setRecordingBtnState('processing')
        // this is  an instance of recorder
        const blob = this.recorder.getBlob();

        this.transcriptAudio(blob)
        // document.getElementById('micRecording').src = URL.createObjectURL(blob);
        // Release recording devices by stoping all tracks of the stream.
        this.clearStreamAndRecorder();
    }

    clearStreamAndRecorder() {
        if (this.stream) {
            this.stream.getTracks().forEach(track => track.stop())
        }
        if (this.recorder) {
            this.recorder.reset();
        }
        this.stopRecording = undefined;
    }

    transcriptAudio(blob) {
        const formData = new FormData();
        formData.append('audio', blob);

        fetch(this.transcriptUrlValue, {
            method: 'POST',
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            },
            body: formData
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.data.results) {
                    this.answerTranscriptInputTarget.value = response.data.results.join(',');
                    this.submitAnswer();
                } else {
                    this.setRecordingBtnState('record')
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            })
    }


    setRecordingBtnState(state) {
        if (state === 'processing') {
            this.recordBtnTarget.disabled = true
            this.recordBtnTarget.classList.add('disabled')
        } else {
            this.recordBtnTarget.disabled = false
            this.recordBtnTarget.classList.remove('disabled')
        }
        this.recordStateTarget.removeAttribute('class');
        this.recordStateTarget.classList.add('position-relative');
        this.recordStateTarget.classList.add(state);
    }

    placeholderAnswerTargetConnected(target) {
        this.originalAnswerPlaceholder = target.innerText
    }

    pairCardInputCheck(e) {
        this.pairCards[e.target.name] = {target: e.target, order: e.target.dataset.order}
        if (Object.keys(this.pairCards).length == 2) {
            const values = Object.values(this.pairCards);
            if (values[0].order == values[1].order) {
                values.forEach((value) => {
                    value.target.disabled = true
                    value.target.checked = false
                    value.target.dataset.correct = 'true';
                    document.querySelector(`label[for='${value.target.id}']`).classList.add('pair-correct')

                    // Submit
                    if(this.checkIfAllPairCardsAreCorrect()){
                        this.submitAnswer();
                    }
                })
            } else {
                values.forEach((value) => {
                    value.target.checked = false
                    value.target.disabled = true
                    const elem = document.querySelector(`label[for='${value.target.id}']`)
                    elem.classList.add('pair-wrong')

                    elem.addEventListener('animationend', function() {
                        value.target.checked = false
                        value.target.disabled = false
                        document.querySelector(`label[for='${value.target.id}']`).classList.remove('pair-wrong')
                    });
                })
            }
            this.pairCards = {}
        }
    }

    checkIfAllPairCardsAreCorrect(e) {
        const radioInputs = document.querySelectorAll('input[type="radio"]');
        const dataAttributeName = 'correct';
        let allHaveDataAttribute = true;
        for (const radioInput of radioInputs) {
            // Check if the data attribute exists and is not empty
            if (!radioInput.hasAttribute(`data-${dataAttributeName}`) || radioInput.getAttribute(`data-${dataAttributeName}`) === '') {
                allHaveDataAttribute = false;
                break; // Exit the loop as soon as one input doesn't have the attribute
            }
        }

        return allHaveDataAttribute;
    }

    playAnswerOptionAudio(e) {
        const id = e.target.id
        const audio = document.getElementById(`${id}_audio`);
        if (e.target.checked && audio && audio.src) {
            // @see https://web.dev/optimize-long-tasks/?utm_source=devtools
            // push execution to separate task
            setTimeout(() => {
                let listeningExerciseSelector = [...this.audioSelectors].find((selector) => selector.dataset.audioTarget && !selector.paused)
                if(this.cardTypeValue === 'listen_and_answer_build_foreign_to_native' && listeningExerciseSelector && !listeningExerciseSelector.paused){
                    return;
                }
                this.pauseOtherAudioSelectors(audio);
                audio.currentTime += parseFloat(audio.dataset.startOffset || 0.2);
                audio.play();
                audio.onended = (() => audio.currentTime = 0)
            }, 0)
        }
    }

    pauseOtherAudioSelectors(excludedSelector) {
        this.audioSelectors.forEach((audioSelector) => {
            if (audioSelector.src && audioSelector !== excludedSelector) {
                audioSelector.pause();
                audioSelector.currentTime = 0;
                audioSelector.dispatchEvent(new Event('ended'));
            }
        })
    }

    submitConversationLine(e){
        this.presentLoader(1000)
        Rails.fire(this.answerFormTarget, 'submit');
    }

    presentLoader(delay = 1000) {
        if (!this.loaderTimeout && !document.getElementById('dialog-loader')) {
            this.loaderTimeout = setTimeout(() => {
                let loader = `<div class="spinner-dialog spinner-border" id="dialog-loader"></div>`
                document.getElementById("dialog-container").insertAdjacentHTML("beforeend", loader)
            }, delay)
        }
    }


    connect() {
        document.addEventListener('ajax:beforeSend', () => {

        })

        document.addEventListener('ajax:send', () => {
            if (this.inputElements) {
                this.inputElements.forEach(x => {
                    x.disabled = true
                });
            }
        })

        document.addEventListener('ajax:error', () => {
            if (this.inputElements) {
                this.inputElements.forEach(x => {
                    x.disabled = false
                });
            }
        })

        document.addEventListener('ajax:success', () => {
            const audioElement = document.querySelector('[data-audio-play-after-submit="true"]');
            if (audioElement && audioElement.src) {
                this.pauseOtherAudioSelectors(audioElement);
                // Remove disabled data attribute to enable audio_controller playback on click/tap
                audioElement.removeAttribute('data-disabled')
                audioElement.parentElement.classList.remove('disabled')
                audioElement.play();
            }
            if (this.hasRecordStateTarget) {
                this.setRecordingBtnState('record')
                this.recordStateTarget.classList.add('d-none')
            }
            document.getElementById('dialog-loader')?.remove();
            clearTimeout(this.loaderTimeout)
            this.loaderTimeout = null;
        })
        if (this.hasPlaceholderAnswerTarget && this.hasAnswerFormTarget) {
            this.placeholderAnswerTarget.addEventListener('click', () => {
                let container = this.answerFormTarget.querySelector('.answer-options')

                this.inputElements.forEach((e) => {
                    container.classList.add('fadein_animated')
                    container.addEventListener("animationend", () => {
                        container.classList.remove('fadein_animated')
                    }, false);
                })
            })
        }
        this.dispatch("lilata:mobile-app-messenger-event", {prefix: false, detail: {data: {type: 'hide_tab_bar'}}})
        this.audioSelectors = document.querySelectorAll('audio')
    }


}
